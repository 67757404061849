import React from 'react'

const WhyChoose = () => {


  return (
    <section className="benifor nftoken pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Why Choose Coinsclone </span>As Your NFT Token Development Company?
            </h2>
            <p className="text-center">
            Coinsclone offers you top-notch NFT token development services that catch the eyes of digital world users. We ensure to offer your future product that can be integrated into a business canvas.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Dedicated team</h3>
                <p className="pharagraph">
                We possess a dedicated set of NFT token developers who help to design your NFTs with intricate features.
                </p>
              </div>
              <div className="square" >
                <h3>On-time Delivery</h3>
                <p className="pharagraph">
                Our development company ensures to offer your NFT token project within the time limit. We create potential NFTs as soon as possible.
                </p>
              </div>
              <div className="square" >
                <h3>Prolonged Support</h3>
                <p className="pharagraph">
                Even after the completion of NFT token development, we offer continuous support to rectify the technical errors and bugs.
                </p>
              </div>
              <div className="square" >
                <h3>Advanced Tech Stack</h3>
                <p className="pharagraph">
                As pioneers in the crypto industry, we prefer to pick tech-savvy stacks, programming languages, resources, and tools to create NFTs. 
                </p>
              </div>
              <div className="square" >
                <h3>End-to-end Security</h3>
                <p className="pharagraph">
                Our development team won’t compromise with quality and security protocols. We integrate various other security protocols for your NFT token.
                </p>
              </div>
              <div className="square" >
                <h3>Affordable Pricing</h3>
                <p className="pharagraph">
                We are experts in offering a cutting-edge project at a reasonable cost that suits every newbie startup’s budget.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default WhyChoose