import React from "react"

class DevServices extends React.Component {

  render() {
    return (
      
      <section className="icon whitelabel-innove nftoken pt-100 secureof">
        <div className="container">
          <h2 className="heading-h2 text-center">Our NFT Token <span className='bluecolor'>Development Services</span></h2>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Token Creation
                </h3>
                <p className="pharagraph">Our development team provides you with cutting-edge token creation by backing all kinds of real-world assets. We weave delicate and unique non-fungible tokens under an affordable business budget. You can get custom NFT creation solutions, integration to various NFT marketplaces, and robust security with our services.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Smart Contract Development
                </h3>
                <p className="pharagraph">We give a helping hand to startups to create secure and efficient smart contracts for non-fungible tokens. Our technical team even integrates complex features to deliver advanced-level NFTs. Embrace your NFTs with higher security protocols, and hi-tech resources.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Smart Contract Auditing
                </h3>
                <p className="pharagraph mb-lg-0">Our auditing team offers top-notch smart contract auditing services for your NFTs to ensure the quality, and working. Stay ahead of the pack with our NFT smart contract auditing services in the competitive crypto market. We ensure to find the vulnerable data breaches, and security risks to offer potential NFT token development.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Music NFTs
                </h3>
                <p className="pharagraph mb-lg-0">Elevate the music industry with our dedicated music NFTs. We help you to develop NFTs where music albums, pieces of popular music, songs, video clips, etc can be tokenized. Gain popularity with our digitalized NFT music tokens for crypto enthusiasts and investors at a reasonable cost.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Art NFTs
                </h3>
                <p className="pharagraph mb-lg-0">We are well-versed in backing art, sketches, vintage drawings, etc as an exemplary non-fungible token. Our development team ensures to convert or tokenize any kind of complex real-world unique art piece into a valuable NFT Art token. Interested artists can upload their art and mint them into an NFT within seconds.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Fractional NFTs
                </h3>
                <p className="pharagraph mb-lg-0">Our development team is experienced in creating non-fungible tokens that are fractional in nature. We create potential fractional NFTs that can be broken into fractions to be sold for crypto investors.  Even popular music concert tickets can also be minted into a non-fungible token on marketplace platforms.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default DevServices
