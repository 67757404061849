import React from 'react'




class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis py-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center">NFT Token Development </h2>
            <div className="text-center">
              <p className="pharagraph text-center">NFT token development is a process of tokenizing real-world assets into non-fungible tokens. We, the team of Coinsclone ensure to create your NFT token in the best way possible. Our development team gives assurance in offering the creation services for all kinds of non-fungible tokens. From ideating the concept for NFT creation to building the cutting-edge NFT token, we will take complete responsibility.
              </p>
              <p className="pharagraph text-center mb-0">With our expertise, technical experience, and tailored solutions, we provide startups with numerous options. Embark the NFT token development journey with our extended developers and harness the potential. We assist investors, crypto users, and traders in tokenizing a limited and unique NFT token.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis