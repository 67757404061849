import React from 'react'



class VariousBlock extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className='gray-bg'>
        <div className="container">
        <h2 className="heading-h2"><span className="bluecolor">Blockchain Standards </span> We Use To Create NFT Token</h2>
          <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/ethereum.png" alt="Ethereum image1" />
              <span>Ethereum</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/solana.png" alt="Solana image1" /><span>Solana</span></p>
            </div>
            <div className="revenue">
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/binance-smart-chain.png" alt="Binance Smart Chain image1" /><span>Binance Smart Chain</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/polygon.png" alt="Polygon image1" /><span>Polygon</span></p>
            </div>
            <div className="revenue">
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/tron-token.png" alt="Tron image1"  /><span>Tron</span></p>
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/wave.png" alt="Waves image1" /><span>Waves</span></p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default VariousBlock