import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">NFT Token Development Company</h1>
            <p className="sub-heading">Coinsclone, a prominent NFT token development company offers top-notch non-fungible token creation services for startups.</p>
            <p className="sub-small">Unlock the unlimited options to create your non-fungible token that showers huge opportunities in the digital world.</p>
          </div>
          <div className="quick text-center d-none d-md-block">
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/nfttoken/nft-token-development.png"
            alt="NFT Token Development Company image1"
            width={810}
          />
          </div>
          <div className="text-center mt-4">
            <ButtonStartfree />
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection