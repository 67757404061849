import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'




class UsecaseOf extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Case</span> of our</span>
                NFT Token Development</h3>
                <p className="pharagraph head text-center">Our NFT token development shows numerous use cases in different kinds of industries.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
            <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/nfttoken/use-case.webp"
            alt="Use Case of NFT Token Development image1"
            width={500}
          />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Art -</b> Digital Art can be created into NFTs and traded as tokens. The ownership is indisputable and is automatically traced throughout the chain.
              </p>
              <p className="pharagraph" ><b>Collectibles -</b> These are rare items that are often very difficult to trace using conventional means, and also difficult to replicate. With NFTs, these collectibles can be bought and multiplied.
              </p>
              <p className="pharagraph" ><b>Copyright protection -</b>  Internationally protecting the copyright of songs and fashion designs can be difficult, but not with NFTs because the original ownership can be traced throughout the downline.
              </p>
              <p className="pharagraph" ><b>Licenses & certificates -</b>  NFTs can help to irrefutably verify licenses and certificates by saving the authentications as smart contracts and then creating NFTs.
              </p>
              <p className="pharagraph" ><b>Sports -</b> Counterfeit sales of tickets and sports merchandise can be curbed by selling them as tokens instead of conventional paper tickets and cards.
              </p>
              <p className="pharagraph mb-0" ><b>Real estate -</b> NFTs can simplify and secure real estate ownership and transfer of properties by simply executing smart contracts instead of going in-depth.
              </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default UsecaseOf