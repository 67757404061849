import React from 'react'


const Exponential = () => {


  return (
    <section className="industries pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Exponential Features </span> Of Our NFT Tokens
            </h2>
            <p className="pharagraph head text-center">We aim to create an NFT token that helps to shape your future with immense profits. We utilize a higher-level tech stack for NFT token development.
            </p>
        </div>
        <div className='row popular we-offer'>
            <div className='col-md-12 col-lg-3'>
                <h3>Unique</h3>
                <p className='pharagraph'>Our NFTs are created that possess unique qualities that differ from other crypto tokens. Our NFTs are not replicated or copied from any other non-fungible token.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Ownership</h3>
                <p className='pharagraph'>NFTs retain the ownership stored on a distributed ledger technology with the respective account. you get access to the original NFT when you hold the token.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Indivisible</h3>
                <p className='pharagraph'>Unlike other fungible crypto tokens, the NFT tokens cannot be divided, exchanged, or split. The transactions are done by transferring the whole ownership to a creator or user.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Multi-chain support </h3>
                <p className='pharagraph'>Our development team ensures to create NFTs that are interoperable with multiple blockchain networks. We tend to design the tokens based on your crypto business specifications.</p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Exponential